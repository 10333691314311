
  import { ValidationProvider } from "vee-validate";
  import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";
  import vSelect from "vue-select";
  import OpenIndicator from "@/assets/svg/heroicons/selector.svg";
  import userImage from "@/components/general/user-image.vue";

  vSelect.props.components.default = () => ({ OpenIndicator });

  @Component({
    components: {
      ValidationProvider,
      VSelect: vSelect,
      userImage,
    },
  })
  export default class FormSelect extends Vue {
    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() placeholder?: string;

    @Prop() rules?: string | object;

    @Prop() options?: string[];

    @Prop() label?: string;

    @Prop({ default: false }) multiple?: boolean;

    @Prop({ default: false }) taggable?: boolean;

    @Prop({ default: true }) clearable?: boolean;

    @Prop({ default: true }) searchable?: boolean;

    @Prop({ default: " text-12" }) labelClass!: string;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: true }) showError?: boolean;

    @Prop({ default: false }) canAddNew?: boolean;

    @Prop({ default: (o: any) => o }) reduce?: Function;

    @Prop({ default: undefined }) createOption?: Function;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    @Ref() readonly input!: any;

    localValue = "";

    private get formValdiated(): boolean {
      return this.provider.$_veeObserver?.flags?.validated;
    }

    private get validateAs(): string {
      if (this.label) {
        return this.label;
      }

      if (this.placeholder) {
        return this.placeholder;
      }

      if (this.name) {
        return this.name;
      }

      return `Select field`;
    }

    enabledOptions(option: any) {
      return !option.hasOwnProperty("disabled") || (option.hasOwnProperty("disabled") && !option.disabled);
    }

    @Emit("input")
    private handleInput() {
      return this.localValue;
    }

    @Emit("new")
    addNew() {
      this.input.open = false;

      return true;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: string) {
      this.localValue = newValue;
    }
  }
