
  import { Editor, EditorContent } from "@tiptap/vue-2";
  import StarterKit from "@tiptap/starter-kit";
  import Underline from "@tiptap/extension-underline";
  import { ValidationProvider } from "vee-validate";
  import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";

  @Component({
    components: {
      ValidationProvider,
      EditorContent,
    },
  })
  export default class FormWysiwyg extends Vue {
    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() placeholder?: string;

    @Prop() rules?: string | object;

    @Prop() label?: string;

    @Prop({ default: " text-12" }) labelClass!: string;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: "text" }) type!: string;

    @Ref() input!: HTMLInputElement;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    isFocussed = false;

    validAtleastOnce = false;

    editor: Editor | null = null;

    mounted() {
      this.initEditor();
    }

    async initEditor() {
      this.editor = new Editor({
        content: this.value,
        editorProps: {
          attributes: {
            class:
              "prose caret-secondary-500 max-w-none border border-gray-50 hover:border-secondary-300 focus:border-secondary-500 focus:outline-none min-h-[120px] transition-colors rounded-[8px] pt-[8px] pb-[48px] px-[12px]",
          },
        },
        extensions: [StarterKit, Underline],
        onUpdate: () => {
          if (!this.editor) {
            return;
          }

          this.$emit("input", this.editor.getHTML());
        },
      });
    }

    private get formValdiated(): boolean {
      return this.provider.$_veeObserver?.flags?.validated;
    }

    private get validateAs(): string {
      if (this.label) {
        return this.label;
      }

      if (this.placeholder) {
        return this.placeholder;
      }

      if (this.name) {
        return this.name;
      }

      return `${this.type} field`;
    }

    beforeDestroy() {
      if (!this.editor) {
        return;
      }

      this.editor.destroy();
    }

    private eagerIfDirty(_a: { flags: any; errors: any }) {
      let errors = _a.errors;
      let flags = _a.flags;
      if (errors.length) {
        return {
          on: ["input", "change"],
        };
      }

      if (flags.valid && !this.validAtleastOnce) {
        this.validAtleastOnce = true;
      }

      if (flags.dirty) {
        return {
          on: ["change", "blur", "input"],
        };
      }

      return {
        on: ["change"],
      };
    }

    convertHTML(str: string) {
      return str;
      let doc = new DOMParser().parseFromString(str, "text/html");
      return doc.documentElement.textContent;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: string) {
      if (!this.editor) {
        return;
      }

      const isSame = this.editor.getHTML() === this.convertHTML(newValue);

      if (isSame) {
        return;
      }

      console.log(this.convertHTML(newValue));

      this.editor.commands.setContent(this.convertHTML(newValue), false);
    }
  }
