import store from "@/store/index";

/**
 * Guard logout middleware
 * Redirect to the login page when the user is not logged in
 *
 * @param {to} string The route the user was comming from
 * @param {from} string The route the user is going to
 * @param {next} function The next route funciton
 */
export default async function guardLogout(to: any, from: any, next: any): Promise<void> {
  await store.dispatch("auth/logout");

  if (to.name === "password-reset") {
    return next();
  }

  return next({ name: "login" });
}
