import { ActionTree, MutationTree, GetterTree } from "vuex";
import Vue from "vue";

const initialState: BidState = {
  all: [],
  total: undefined,
  viewing: undefined,
};

const getters: GetterTree<BidState, RootState> = {
  all(state: BidState): Bid[] {
    return state.all;
  },
  total(state: BidState): number | undefined {
    return state.total;
  },
  viewing(state: BidState): Bid | undefined {
    return state.viewing;
  },
  winningBid(state: BidState): Bid | undefined {
    return state.all.find((bid: Bid) => bid.status === "APPROVED");
  },
  rejectedBids(state: BidState): Bid[] {
    return state.all.filter((bid: Bid) => bid.status === "REJECTED");
  },
};

const mutations: MutationTree<BidState> = {
  RESET(state: BidState) {
    state.all = [];
    state.viewing = undefined;
  },
  UPDATE_MODEL(state: BidState, payload: Bid) {
    const index = state.all.findIndex((model: Bid) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);

      if (payload.status === "APPROVED") {
        let i = 0;
        state.all.forEach((bid: Bid) => {
          if (i !== index) {
            bid.status = "REJECTED";
          }
          i++;
        });
      }
    }

    if (state.viewing && state.viewing.id === payload.id) {
      state.viewing = payload;
    }
  },
  DELETE_MODEL(state: BidState, id: number) {
    // @ts-ignore
    const index = state.all.findIndex((model: Bid) => model.id == id);

    if (index >= 0) {
      state.all.splice(index, 1);
    }

    // @ts-ignore
    if (state.viewing && state.viewing.id === id) {
      state.viewing = undefined;
    }
  },
  ADD_MODEL(state: BidState, payload: Bid) {
    state.all.push(payload);
  },
  SET_MODELS(state: BidState, payload: Bid[]) {
    state.all = payload;
  },
  SET_TOTAL(state: BidState, payload: number) {
    state.total = payload;
  },
  ADD_MODELS(state: BidState, payload: Bid[]) {
    state.all = [...state.all, ...payload];
  },
  SET_VIEWING(state: BidState, payload: Bid) {
    state.viewing = payload;
  },
};

const actions: ActionTree<BidState, RootState> = {
  create({ commit, rootState }, payload: BidCreatePayload) {
    return rootState.api
      .post(`bids`, payload, {
        withCredentials: true,
      })
      .then((response: { data: Bid }) => {
        commit("ADD_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: BidUpdatePayload) {
    return rootState.api
      .patch(`bids/${payload.id}`, payload, {
        withCredentials: true,
      })
      .then((response: { data: Bid }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  index({ commit, rootState }, payload: { query?: string; activity_id: number; after?: Array<string>; seller_id?: number | boolean }) {
    const params = new URLSearchParams();

    let headers = {};
    if (payload.seller_id) {
      headers = { "x-seller-id": payload.seller_id };
    }

    if (payload) {
      if (payload.after && payload.after !== null) {
        payload.after.forEach((a: string) => {
          params.append("after[]", a);
        });
      }
      if (payload.hasOwnProperty("activity_id")) {
        params.append("q[and][or][activity_id]", payload.activity_id.toString());
      }
      if (payload.query) {
        params.append("q[and][or][name][]", `like:*${encodeURIComponent(payload.query)}*`);
        params.append("q[and][or][name][]", `match:${encodeURIComponent(payload.query)}`);
      }
    }

    params.append("s[created_at]", "desc");
    params.append("per_page", "30");

    const paramsString = decodeURIComponent(params.toString());
    return rootState.api
      .get(`bids?${paramsString}`, {
        withCredentials: true,
        headers,
      })
      .then((response: { data: Bid[]; total: number }) => {
        if (payload && payload.after && payload.after !== null) {
          commit("ADD_MODELS", response.data);
        } else {
          commit("SET_MODELS", response.data);
        }
        commit("SET_TOTAL", response.total);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: { id: number }) {
    return rootState.api
      .get(`bids/${payload.id}`, {
        withCredentials: true,
      })
      .then((response: { data: Bid }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ commit, rootState }, payload: { id: number }) {
    return rootState.api
      .delete(`bids/${payload.id}`, { withCredentials: true })
      .then(() => {
        commit("DELETE_MODEL", payload.id);

        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  setViewing({ commit }, payload: Bid) {
    commit("UPDATE_MODEL", payload);
    commit("SET_VIEWING", payload);
  },
  clear({ commit }) {
    commit("SET_MODELS", []);
    commit("SET_VIEWING", null);
  },
  clearViewing({ commit }) {
    commit("SET_VIEWING", null);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
