function isObj(a: Object) {
  if (!!a && a.constructor === Object) {
    return true;
  }
  return false;
}

function _st(z: any, g: any) {
  return "" + (g != "" ? "[" : "") + z + (g != "" ? "]" : "");
}

function queryFromObject(params: any, skipobjects?: any, prefix?: any) {
  if (skipobjects === void 0) {
    skipobjects = false;
  }
  if (prefix === void 0) {
    prefix = "";
  }
  let result = "";
  if (typeof params != "object") {
    return prefix + "=" + encodeURIComponent(params) + "&";
  }
  for (const param in params) {
    const c = "" + prefix + _st(param, prefix);
    if (isObj(params[param]) && !skipobjects) {
      result += queryFromObject(params[param], false, "" + c);
    } else if (Array.isArray(params[param]) && !skipobjects) {
      params[param].forEach(function (item: any, ind: any) {
        result += queryFromObject(item, false, c + "[" + ind + "]");
      });
    } else {
      result += c + "=" + encodeURIComponent(params[param]) + "&";
    }
  }
  return result;
}

export { queryFromObject };
