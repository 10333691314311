import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";

export interface IndexReviewPayload {
  activity_id: number;
  q?: {
    and: {
      account_id: number;
    };
  };
  skip_mutation?: boolean;
}

export interface CreateReviewPayload {
  score: number;
  message?: string;
  activity_id: number;
  account_id: number;
  type: "AFTER_PUBLICATION" | "AFTER_SALE" | "AFTER_SALES_AGREEMENT";
}

export interface Review {
  id: number;
  score: number | null;
  message: string | null;
  type: "AFTER_PUBLICATION" | "AFTER_SALE" | "AFTER_SALES_AGREEMENT";
  activity_id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface ReviewState {
  all: Review[];
}

const initialState: ReviewState = {
  all: [],
};

const getters: GetterTree<ReviewState, RootState> = {
  all(state: ReviewState): Review[] {
    return state.all;
  },
};

const mutations: MutationTree<ReviewState> = {
  RESET(state: ReviewState) {
    state.all = [];
  },
  ADD_MODEL(state: ReviewState, payload: Review) {
    state.all.push(payload);
  },
  SET_MODELS(state: ReviewState, payload: Review[]) {
    state.all = payload;
  },
  ADD_MODELS(state: ReviewState, payload: Review[]) {
    state.all = [...state.all, ...payload];
  },
  UPDATE_MODEL(state: ReviewState, payload: Review) {
    const index = state.all.findIndex((model: Review) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }
  },
};

const actions: ActionTree<ReviewState, RootState> = {
  index({ commit, rootState }, payload: IndexReviewPayload) {
    return rootState.api
      .get(`activities/${payload.activity_id}/reviews?${queryFromObject(payload)}`, { withCredentials: true })
      .then((response: { data: Review[]; total: number }) => {
        if (!payload.skip_mutation) {
          commit("SET_MODELS", response.data);
        }

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: number) {
    return rootState.api
      .get(`reviews/${payload}`, { withCredentials: true })
      .then((response: { data: Review }) => {
        commit("ADD_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  create({ commit, rootState }, payload: CreateReviewPayload) {
    return rootState.api
      .post(`activities/${payload.activity_id}/reviews`, payload, { withCredentials: true })
      .then((response: { data: Review }) => {
        commit("ADD_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  /*
    update({ commit, rootState }, { payload, id }: { payload: CreateReviewPayload; id: number }) {
        return rootState.api
            .put(`reviews/${id}`, payload, { withCredentials: true })
            .then((response: { data: Review }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    clear({ commit }) {
        commit("RESET");
    },*/
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
