
  import { Component, Vue, Prop } from "vue-property-decorator";

  @Component
  export default class FormSubmit extends Vue {
    @Prop({ default: false }) readonly loading!: boolean;

    @Prop({ default: false }) readonly success!: boolean;

    @Prop({ default: false }) readonly disabled!: boolean;

    @Prop({ default: "submit" }) readonly type!: "button" | "submit";

    handleClick() {
      if (this.type === "submit") {
        return;
      }

      this.$emit("click");
    }
  }
