import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";

export interface IndexAppointmentPayload {
  seller_id?: number | boolean;
  q?: {
    and?: {
      activity_id?: number;
      planned_at?: string | string[];
    };
  };
  per_page?: number;
  s?: {
    created_at?: "asc" | "desc";
    updated_at?: "asc" | "desc";
    planned_at?: "asc" | "desc";
  };
  skip_mutation?: boolean;
}

export interface CalendarAction {
  id: number;
  name: string;
  duration: number | null;
}

const initialState: AppointmentState = {
  all: [],
  actions: [],
  total: undefined,
  viewing: undefined,
};

const getters: GetterTree<AppointmentState, RootState> = {
  all(state: AppointmentState): Appointment[] {
    return state.all;
  },
  total(state: AppointmentState): number | undefined {
    return state.total;
  },
  actions(state: AppointmentState): CalendarAction[] {
    return state.actions;
  },
  viewing(state: AppointmentState): Appointment | undefined {
    return state.viewing;
  },
};

const mutations: MutationTree<AppointmentState> = {
  RESET(state: AppointmentState) {
    state.all = [];
    state.viewing = undefined;
  },
  UPDATE_MODEL(state: AppointmentState, payload: Appointment) {
    const index = state.all.findIndex((model: Appointment) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }

    if (state.viewing && state.viewing.id === payload.id) {
      state.viewing = payload;
    }
  },
  DELETE_MODEL(state: AppointmentState, id: number) {
    // @ts-ignore
    const index = state.all.findIndex((model: Appointment) => model.id == id);

    if (index >= 0) {
      state.all.splice(index, 1);
    }

    // @ts-ignore
    if (state.viewing && state.viewing.id === id) {
      state.viewing = undefined;
    }
  },
  ADD_MODEL(state: AppointmentState, payload: Appointment) {
    state.all.push(payload);
  },
  SET_MODELS(state: AppointmentState, payload: Appointment[]) {
    state.all = payload;
  },
  SET_ACTIONS(state: AppointmentState, payload: CalendarAction[]) {
    state.actions = payload;
  },
  SET_TOTAL(state: AppointmentState, payload: number) {
    state.total = payload;
  },
  ADD_MODELS(state: AppointmentState, payload: Appointment[]) {
    state.all = [...state.all, ...payload];
  },
  SET_VIEWING(state: AppointmentState, payload: Appointment) {
    state.viewing = payload;
  },
};

const actions: ActionTree<AppointmentState, RootState> = {
  create({ commit, rootState }, payload: AppointmentCreatePayload) {
    return rootState.api
      .post(`appointments`, payload, { withCredentials: true })
      .then((response: { data: Appointment }) => {
        commit("ADD_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  update({ commit, rootState }, { payload, id }: { payload: AppointmentCreatePayload; id: number }) {
    return rootState.api
      .put(`appointments/${id}`, payload, { withCredentials: true })
      .then((response: { data: Appointment }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  index({ commit, rootState }, payload: IndexAppointmentPayload) {
    let headers = {};
    if (payload.seller_id) {
      headers = { "x-seller-id": payload.seller_id };
    }

    return rootState.api
      .get(`appointments?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true, headers })
      .then((response: { data: Activity[]; total: number }) => {
        commit("SET_MODELS", response.data);
        commit("SET_TOTAL", response.total);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  actions({ commit, rootState, state }) {
    if (state.actions && state.actions.length > 0) {
      return new Promise((resolve) => resolve(state.actions));
    }

    return rootState.api
      .get(`appointments/actions`, { withCredentials: true })
      .then((response: { data: CalendarAction[] }) => {
        commit("SET_ACTIONS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  indexV1({ commit, rootState }, payload: { query?: string; activity_id: number; after?: Array<string> }) {
    const params = new URLSearchParams();

    if (payload) {
      if (payload.after && payload.after !== null) {
        payload.after.forEach((a: string) => {
          params.append("after[]", a);
        });
      }
      if (payload.hasOwnProperty("activity_id")) {
        params.append("q[and][or][activity_id]", payload.activity_id.toString());
      }
      if (payload.query) {
        params.append("q[and][or][name][]", `like:*${encodeURIComponent(payload.query)}*`);
        params.append("q[and][or][name][]", `match:${encodeURIComponent(payload.query)}`);
      }
    }

    params.append("s[created_at]", "desc");
    params.append("per_page", "30");

    const paramsString = decodeURIComponent(params.toString());
    return rootState.api
      .get(`appointments?${paramsString}`, { withCredentials: true })
      .then((response: { data: Appointment[]; total: number }) => {
        if (payload && payload.after && payload.after !== null) {
          commit("ADD_MODELS", response.data);
        } else {
          commit("SET_MODELS", response.data);
        }
        commit("SET_TOTAL", response.total);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: { id: number }) {
    return rootState.api
      .get(`appointments/${payload.id}`, {
        withCredentials: true,
      })
      .then((response: { data: Appointment }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ commit, rootState }, payload: number) {
    return rootState.api
      .delete(`appointments/${payload}`, { withCredentials: true })
      .then(() => {
        commit("DELETE_MODEL", payload);

        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  setViewing({ commit }, payload: Appointment) {
    commit("UPDATE_MODEL", payload);
    commit("SET_VIEWING", payload);
  },
  clear({ commit }) {
    commit("SET_MODELS", []);
    commit("SET_VIEWING", null);
  },
  clearViewing({ commit }) {
    commit("SET_VIEWING", null);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
