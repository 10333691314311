import guardAuth from "@/router/middleware/auth";
import { loadView } from "@/router/helpers";

const routes = [
  {
    path: "/instellingen",
    name: "settings",
    component: loadView("auth/settings/index"),
    beforeEnter: guardAuth,
    meta: {
      title: "Instellingen | Hubr",
      section: "auth",
      group: ["employee"],
    },
    children: [
      {
        path: "gebruikers",
        name: "admin-users",
        component: loadView("admin/settings/users/index"),
        beforeEnter: guardAuth,
        meta: {
          title: "Gebruikers | Hubr",
          section: "auth",
          group: ["employee"],
        },
      },
      {
        path: "kantoren",
        name: "admin-offices",
        component: loadView("admin/settings/offices/index"),
        beforeEnter: guardAuth,
        meta: {
          title: "Kantoren | Hubr",
          section: "auth",
          group: ["employee"],
        },
      },
      {
        path: "partners",
        name: "admin-partners",
        component: loadView("admin/settings/partners/index"),
        beforeEnter: guardAuth,
        meta: {
          title: "Partners | Hubr",
          section: "auth",
          group: ["employee"],
        },
      },
      {
        path: "partners/create",
        name: "admin-partners-create",
        component: loadView("admin/settings/partners/create"),
        beforeEnter: guardAuth,
        meta: {
          title: "Partners uitnodigen | Hubr",
          section: "auth",
          group: ["employee"],
        },
      },
      {
        path: "partners/:id",
        name: "admin-partners-edit",
        component: loadView("admin/settings/partners/edit"),
        beforeEnter: guardAuth,
        meta: {
          title: "Partner aanpassen | Hubr",
          section: "auth",
          group: ["employee"],
        },
      },
    ],
  },
  {
    path: "/account",
    name: "account",
    component: loadView("auth/account/index"),
    beforeEnter: guardAuth,
    meta: {
      title: "Profiel | Hubr",
      section: "auth",
      group: ["employee"],
    },
    children: [
      {
        path: "mijn-gegevens",
        name: "account-personal-info",
        component: loadView("auth/account/personal-info"),
        beforeEnter: guardAuth,
        meta: {
          title: "Mijn gegevens | Hubr",
          section: "auth",
          group: ["employee"],
        },
      },
    ],
  },
];

export default routes;
