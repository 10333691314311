import guardAuth from "@/router/middleware/auth";
import { loadView } from "@/router/helpers";

const routes = [
  {
    path: "/sales-pipeline",
    name: "sales-pipeline",
    component: loadView("auth/sales-pipeline/index"),
    beforeEnter: guardAuth,
    meta: {
      title: "Inkoop pipeline | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },
];

export default routes;
