import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";

export interface IndexDocumentTaskPayload {
  q?: {
    and: {};
  };
  per_page?: number;
}

export interface CreateDocumentTaskPayload {
  task_id: number;
  activity_id: number;
  document_id: number;
}

export interface PatchDocumentTaskPayload {
  id: number;
  task_id?: number;
  activity_id?: number;
  document_id?: number;
}

export interface DocumentTask {
  id: number;
  document_id: number;
  task_id: number;
  sort?: Array<string | number>;
}

interface DocumentTaskState {
  all: DocumentTask[];
}

const initialState: DocumentTaskState = {
  all: [],
};

const getters: GetterTree<DocumentTaskState, RootState> = {
  all(state: DocumentTaskState): DocumentTask[] {
    return state.all;
  },
};

const mutations: MutationTree<DocumentTaskState> = {
  RESET(state: DocumentTaskState) {
    state.all = [];
  },
  ADD_MODEL(state: DocumentTaskState, payload: DocumentTask) {
    state.all.push(payload);
  },
  SET_MODELS(state: DocumentTaskState, payload: DocumentTask[]) {
    state.all = payload;
  },
  ADD_MODELS(state: DocumentTaskState, payload: DocumentTask[]) {
    state.all = [...state.all, ...payload];
  },
  UPDATE_MODEL(state: DocumentTaskState, payload: DocumentTask) {
    const index = state.all.findIndex((model: DocumentTask) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }
  },
};

const actions: ActionTree<DocumentTaskState, RootState> = {
  create({ commit, rootState }, payload: CreateDocumentTaskPayload) {
    return rootState.api
      .post(`document-tasks`, payload, { withCredentials: true })
      .then((response: { data: DocumentTask }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: PatchDocumentTaskPayload) {
    return rootState.api
      .patch(`document-tasks/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: DocumentTask }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
