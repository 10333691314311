import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";
import { WorkStatus } from "./activity.store";

export interface indexSpotlightPayload {}

export interface SpotlightItem {
  office_id: number;
  id: string;
  matchable: string;
  matchable_formatted: string;
  activity: {
    property_address: {
      additional: string;
      city: string;
      country: string;
      number: string;
      zip: string;
      street: string;
    };
    work_status: WorkStatus;
    office: string;
  };
}

interface SpotlightState {
  all: SpotlightItem[];
}

const initialState: SpotlightState = {
  all: [],
};

const getters: GetterTree<SpotlightState, RootState> = {
  all(state: SpotlightState): SpotlightItem[] {
    return state.all;
  },
};

const mutations: MutationTree<SpotlightState> = {
  RESET(state: SpotlightState) {
    state.all = [];
  },
  ADD_MODEL(state: SpotlightState, payload: SpotlightItem) {
    state.all.unshift(payload);
  },
  SET_MODELS(state: SpotlightState, payload: SpotlightItem[]) {
    state.all = payload;
  },
  ADD_MODELS(state: SpotlightState, payload: SpotlightItem[]) {
    state.all = [...state.all, ...payload];
  },
};

const actions: ActionTree<SpotlightState, RootState> = {
  index({ commit, rootState }, payload: indexSpotlightPayload) {
    return rootState.api
      .get(`spotlights?${queryFromObject(payload)}`, { withCredentials: true, headers: { "x-office-id": null } })
      .then((response: { data: SpotlightItem[]; total: number }) => {
        commit("SET_MODELS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
