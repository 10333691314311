
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { formatTooltip } from "@/utils/formatters";

  @Component
  export default class GeneralUserImage extends Vue {
    @Prop() user!: { first_name: string; last_name: string; media: Media | null; preview_url: string | null };

    @Prop({ default: false }) showTooltip!: boolean;

    formatTooltip = formatTooltip;
  }
