function loadView(view: string) {
  return () => import(`@/views/${view}.vue`);
}

function handleUnauthorized(error: ErrorResponse, next: any) {
  if (error.status === 401) {
    next({ name: "error", params: { code: "401" } });
  }
}

export { loadView, handleUnauthorized };
