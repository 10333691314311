import guardAuth from "@/router/middleware/auth";
import { loadView } from "@/router/helpers";

const routes = [
  {
    path: "/dossier/:id/mandaat",
    name: "dossier-mandate-create",
    component: loadView("auth/mandate/create"),
    beforeEnter: guardAuth,
    meta: {
      title: "Bemiddelingsovereenkomst | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },
  {
    path: "/dossier/:id/mandaat/:mandateId/preview",
    name: "dossier-mandate-preview",
    component: loadView("auth/mandate/preview"),
    beforeEnter: guardAuth,
    meta: {
      title: "Bemiddelingsovereenkomst | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },
];

export default routes;
