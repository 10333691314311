
  import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";
  import { format, parse } from "fecha";
  import { ValidationProvider } from "vee-validate";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import { DataDef } from "vue/types/options";

  @Component({
    components: {
      DatePicker,
      ValidationProvider,
    },
  })
  export default class FormDate extends Vue {
    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() label?: string;

    @Prop({ default: "" }) inputClass?: string;

    @Prop({ default: false }) hideYear?: boolean;

    @Prop({ default: false }) disabled?: boolean;

    @Prop({ default: false }) clearable?: boolean;

    @Prop({ default: "date" }) type?: "date" | "datetime";

    @Prop() rules?: string | { required?: boolean };

    @Prop({ default: "Datum" }) placeholder?: string;

    @Prop({ default: "text-12" }) labelClass!: string;

    @Prop({ default: "DD MMM." }) format!: string;

    @Prop({ default: true }) showError?: boolean;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    localValue: Date | null = null;

    open = false;

    isFocussed = false;

    validAtleastOnce = false;

    locale = {
      formatLocale: {
        firstDayOfWeek: 1,
        months: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
        monthsShort: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
        weekdays: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
        weekdaysShort: ["zo", "ma", "di", "wo", "do", "vr", "za"],
        weekdaysMin: ["zo", "ma", "di", "wo", "do", "vr", "za"],
      },
    };

    momentFormat = {
      parse: (value: string, fmt: string) => {
        if (value && value.length === 8 && !value.includes("-")) {
          value = `${value.slice(0, 2)}-${value.slice(2, 4)}-${value.slice(4, 8)}`;
        }

        return parse(value, fmt);
      },
    };

    get isRequired() {
      return (
        (this.rules && typeof this.rules === "string" && this.rules.includes("required") && !this.rules.includes("required_if")) ||
        (typeof this.rules === "object" && this.rules.hasOwnProperty("required") && this.rules.required)
      );
    }

    get formValdiated(): boolean {
      return this.provider.$_veeObserver?.flags?.validated;
    }

    get validateAs(): string {
      if (this.label) {
        return this.label;
      }

      if (this.placeholder) {
        return this.placeholder;
      }

      if (this.name) {
        return this.name;
      }

      return `${this.type} field`;
    }

    handleClose(e: any) {
      if (!this.open) {
        return;
      }

      if (!e.target.closest(".mx-datepicker-popup")) {
        this.open = false;
      }
    }

    eagerIfDirty(_a: { flags: any; errors: any }) {
      let errors = _a.errors;
      let flags = _a.flags;
      if (errors.length) {
        return {
          on: ["input", "change"],
        };
      }

      if (flags.valid && !this.validAtleastOnce) {
        this.validAtleastOnce = true;
      }

      if (flags.dirty) {
        return {
          on: ["change", "blur", "input"],
        };
      }

      return {
        on: ["change"],
      };
    }

    @Emit("input")
    handleInput(): string | null {
      if (!this.localValue) {
        return null;
      }

      if (this.type === "date") {
        this.localValue = new Date(this.localValue.setHours(23, 59, 59, 999));
      }

      return format(this.localValue, "YYYY-MM-DD[T]HH:mm:ssZ");
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: string) {
      this.localValue = newValue ? new Date(newValue) : null;
    }
  }
