import Vue from "vue";
import * as Sentry from "@sentry/vue";

if (process.env.NODE_ENV !== "development")
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_APP_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    beforeSend: (event, hint) => {
      console.log("send event!");
      const error = hint.originalException as any;
      const filterStatuses = [400, 401, 404];

      if (filterStatuses.includes(error?.response?.status)) return null;
      return event;
    },
  });
