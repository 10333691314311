function hexToRgb(hex: string) {
  const hexWithoutHash = hex.replace("#", "");

  if (hexWithoutHash.length != 6) {
    throw "Only six-digit hex colors are allowed.";
  }

  const aRgbHex = hexWithoutHash.match(/.{1,2}/g);
  const aRgb = [parseInt(aRgbHex![0]!, 16), parseInt(aRgbHex![1]!, 16), parseInt(aRgbHex![2]!, 16)];
  return aRgb;
}

function rgbToHex(rgb: string) {
  return null;
}

export { hexToRgb, rgbToHex };
