
  import { Component, Vue } from "vue-property-decorator";
  import { Loader } from "@/components/forms";
  import { setGlobalDateI18n } from "fecha";

  @Component({
    components: {
      Loader,
    },
  })
  export default class AppBase extends Vue {
    get clientWidth() {
      return window.outerWidth;
    }

    created() {
      setGlobalDateI18n({
        dayNames: ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"],
        monthNames: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
      });
    }

    mounted() {
      const self = this;
      this.setVh();

      window.addEventListener("resize", () => {
        self.setVh();
      });

      window.addEventListener("orientationchange", () => {
        self.setVh();
      });
    }

    private setVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  }
