import guardAuth from "@/router/middleware/auth";
import guardLogout from "@/router/middleware/logout";
import { loadView } from "@/router/helpers";

const routes = [
  {
    path: "/",
    name: "guest",
    component: loadView("guest/index"),
    children: [
      {
        path: "login",
        name: "login",
        beforeEnter: guardAuth,
        component: loadView("guest/login"),
        meta: {
          title: "Inloggen | Hubr",
          section: "guest",
          group: ["guest"],
        },
      },
      {
        path: "magiclogin",
        name: "magiclogin",
        beforeEnter: guardAuth,
        component: loadView("guest/magiclogin"),
        meta: {
          title: "Inloggen | Hubr",
          section: "guest",
          group: ["guest"],
        },
      },
      {
        path: "magiclogin/:token",
        name: "magiclogin-token",
        beforeEnter: guardAuth,
        component: loadView("guest/magiclogin"),
        meta: {
          title: "Inloggen | Hubr",
          section: "guest",
          group: ["guest"],
        },
      },
      {
        path: "uitnodiging/:token",
        name: "register",
        beforeEnter: guardAuth,
        component: loadView("guest/register"),
        meta: {
          title: "Account aanmaken | Hubr",
          section: "guest",
          group: ["guest"],
        },
      },
      {
        path: "/wachtwoord-vergeten",
        name: "password-forgot",
        beforeEnter: guardAuth,
        component: loadView("guest/password-forgot"),
        meta: {
          title: "Wachtwoord vergeten | Hubr",
          section: "guest",
          group: ["guest"],
        },
      },
      {
        path: "/wijzig-wachtwoord",
        name: "password-reset",
        beforeEnter: guardLogout,
        component: loadView("guest/password-reset"),
        meta: {
          title: "Wijzig wachtwoord | Hubr",
          section: "guest",
          group: ["guest"],
        },
      },
      {
        path: "/bod-uitbrengen/:id",
        name: "bid-form",
        component: loadView("guest/bid-form"),
        meta: {
          title: "Bod uitbrengen | Hubr",
          section: "guest",
          group: ["guest"],
        },
      },
      {
        path: "/bod-tekenen/:id",
        name: "bid-sign",
        component: loadView("guest/bid-sign"),
        meta: {
          title: "Bod tekenen | Hubr",
          section: "guest",
          group: ["guest"],
        },
      },
    ],
  },
];

export default routes;
