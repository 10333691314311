import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import { hexToRgb } from "@/utils/color-helpers";

export interface PublicOffice {
  id: number;
  name: string;
  theme: {
    logo: {
      full: Media | null;
      inverse: Media | null;
      email: Media | null;
      small: Media | null;
      small_inverse: Media | null;
    };
    colors: {
      primary: string | null;
      accent: string | null;
      primary_light: string | null;
      accent_light: string | null;
    };
  };
  created_at: string;
  updated_at: string;
}

interface PublicOfficeState {
  viewing?: PublicOffice;
}

const initialState: PublicOfficeState = {
  viewing: undefined,
};

const getters: GetterTree<PublicOfficeState, RootState> = {
  viewing(state: PublicOfficeState): PublicOffice | undefined {
    return state.viewing;
  },
};

const mutations: MutationTree<PublicOfficeState> = {
  SET_VIEWING(state: PublicOfficeState, payload: PublicOffice) {
    state.viewing = payload;
  },
};

const actions: ActionTree<PublicOfficeState, RootState> = {
  read({ commit, rootState }, payload: { office_id?: number; setStyling?: boolean }) {
    const oid = payload.office_id ? payload.office_id : 1;

    return rootState.api
      .get(`offices/${oid}/public`, { withCredentials: true })
      .then((response: { data: PublicOffice }) => {
        commit("SET_VIEWING", response.data);

        if (payload.setStyling && payload.setStyling === true) {
          setCssColors(response.data);

          setFavicons(response.data);
        }

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

function setCssColors(publicOffice: PublicOffice) {
  const root = document.documentElement;

  if (publicOffice.theme.colors.primary) {
    root.style.setProperty("--office-500", publicOffice.theme.colors.primary);
    const primaryRgb = hexToRgb(publicOffice.theme.colors.primary.replace("#", ""));
    if (primaryRgb.length === 3) {
      root.style.setProperty("--office-500-rgb", `${primaryRgb[0]},${primaryRgb[1]},${primaryRgb[2]}`);
    }
  }

  if (publicOffice.theme.colors.primary_light) {
    root.style.setProperty("--office-300", publicOffice.theme.colors.primary_light);
    const primaryLightRgb = hexToRgb(publicOffice.theme.colors.primary_light.replace("#", ""));
    if (primaryLightRgb.length === 3) {
      root.style.setProperty("--office-300-rgb", `${primaryLightRgb[0]},${primaryLightRgb[1]},${primaryLightRgb[2]}`);
    }
  }

  if (publicOffice.theme.colors.accent) {
    root.style.setProperty("--office-accent-500", publicOffice.theme.colors.accent);
    const accentRgb = hexToRgb(publicOffice.theme.colors.accent.replace("#", ""));
    if (accentRgb.length === 3) {
      root.style.setProperty("--office-accent-500-rgb", `${accentRgb[0]},${accentRgb[1]},${accentRgb[2]}`);
    }
  }

  if (publicOffice.theme.colors.accent_light) {
    root.style.setProperty("--office-accent-300", publicOffice.theme.colors.accent_light);
    const accentLightRgb = hexToRgb(publicOffice.theme.colors.accent_light.replace("#", ""));
    if (accentLightRgb.length === 3) {
      root.style.setProperty("--office-accent-300-rgb", `${accentLightRgb[0]},${accentLightRgb[1]},${accentLightRgb[2]}`);
    }
  }
}

function setFavicons(publicOffice: PublicOffice) {
  const favicons = document.querySelectorAll("link[rel~='icon']");

  favicons.forEach((f) => {
    if (publicOffice && publicOffice.theme.logo.small) {
      (f as HTMLLinkElement).href = publicOffice.theme.logo.small.original_url;
    }
  });
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
