import guardAuth from "@/router/middleware/auth";
import { loadView } from "@/router/helpers";

const routes = [
  {
    path: "/",
    name: "seller",
    component: loadView("seller/index"),
    children: [
      {
        path: "/:id/tijdlijn",
        name: "seller-timeline",
        beforeEnter: guardAuth,
        component: loadView("seller/timeline"),
        meta: {
          title: "Uw verkoop",
          section: "seller",
          group: ["seller", "employee"],
        },
      },
      {
        path: "/:id/afspraken",
        name: "seller-appointments",
        beforeEnter: guardAuth,
        component: loadView("seller/appointments"),
        meta: {
          title: "Uw afspraken",
          section: "seller",
          group: ["seller", "employee"],
        },
      },
      {
        path: "/:id/taken",
        name: "seller-tasks",
        beforeEnter: guardAuth,
        component: loadView("seller/tasks"),
        meta: {
          title: "Uw taken",
          section: "seller",
          group: ["seller", "employee"],
        },
      },
      {
        path: "/:id/documenten",
        name: "seller-documents",
        beforeEnter: guardAuth,
        component: loadView("seller/documents"),
        meta: {
          title: "Uw documenten",
          section: "seller",
          group: ["seller", "employee"],
        },
      },
      {
        path: "/:id/estate",
        name: "seller-estate",
        beforeEnter: guardAuth,
        component: loadView("seller/estate"),
        meta: {
          title: "Uw pand",
          section: "seller",
          group: ["seller", "employee"],
        },
      },
      {
        path: "/:id/contacten",
        name: "seller-contacts",
        beforeEnter: guardAuth,
        component: loadView("seller/contacts"),
        meta: {
          title: "Uw contacten",
          section: "seller",
          group: ["seller", "employee"],
        },
      },
      {
        path: "/:id/biedingen",
        name: "seller-bids",
        beforeEnter: guardAuth,
        component: loadView("seller/bids"),
        meta: {
          title: "Uw biedingen",
          section: "seller",
          group: ["seller", "employee"],
        },
      },
    ],
  },
];

export default routes;
