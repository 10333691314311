
  import { ValidationProvider } from "vee-validate";
  import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

  @Component({
    components: {
      ValidationProvider,
    },
  })
  export default class FormInput extends Vue {
    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() placeholder?: string;

    @Prop() rules?: string | object;

    @Prop() label?: string;

    @Prop() step?: number | string;

    @Prop() min?: number;

    @Prop() max?: number;

    @Prop() maxlength?: number;

    @Prop({ default: true }) showError?: boolean;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: "text" }) type!: string;

    @Prop({ default: false }) autoFocus!: boolean;

    @Prop({ default: "text-12" }) labelClass!: string;

    @Prop({ default: "" }) inputClass!: string;

    @Prop({ default: false }) fakeRequired!: boolean;

    @Ref() suffix!: HTMLSpanElement;

    @Ref() prefix!: HTMLSpanElement;

    @Ref() input!: HTMLInputElement;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    isFocussed = false;

    localValue = "";

    prefixWidth = "20px";

    suffixWidth = "20px";

    validAtleastOnce = false;

    hovering = false;

    private mounted() {
      this.setPreSuffixWidth();
      if (this.autoFocus) {
        this.input.focus();
      }
    }

    private updated() {
      this.setPreSuffixWidth();
    }

    private setPreSuffixWidth() {
      if (this.hasPrefix) {
        this.prefixWidth = `${this.prefix.offsetWidth + 16}px`;
      }
      if (this.hasSuffix) {
        this.suffixWidth = `${this.suffix.offsetWidth + 16}px`;
      }
    }

    private get formValdiated(): boolean {
      return this.provider.$_veeObserver?.flags?.validated;
    }

    private get validateAs(): string {
      if (this.label) {
        return this.label;
      }

      if (this.placeholder) {
        return this.placeholder;
      }

      if (this.name) {
        return this.name;
      }

      return `${this.type} field`;
    }

    private get hasPrefix(): boolean {
      return !!this.$slots.prefix;
    }

    private get hasSuffix(): boolean {
      return !!this.$slots.suffix;
    }

    private eagerIfDirty(_a: { flags: any; errors: any }) {
      let errors = _a.errors;
      let flags = _a.flags;
      if (errors.length) {
        return {
          on: ["input", "change"],
        };
      }

      if (flags.valid && !this.validAtleastOnce) {
        this.validAtleastOnce = true;
      }

      if (flags.dirty) {
        return {
          on: ["change", "blur", "input"],
        };
      }

      return {
        on: ["change"],
      };
    }

    @Emit("input")
    private handleInput() {
      return this.localValue;
    }

    @Emit("blur")
    private handleBlur(e: Event): Event {
      if (this.provider && this.provider.flags.dirty) {
        this.validAtleastOnce = true;
      }

      this.isFocussed = false;

      return e;
    }

    @Emit("focus")
    private handleFocus(e: Event): Event {
      // this.isFocussed = true;
      if (this.type === "number") {
        // @ts-ignore
        e.target.select();
      }

      return e;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: string) {
      this.localValue = newValue;
    }
  }
