
  import { ValidationProvider } from "vee-validate";
  import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";
  import { Action } from "vuex-class";
  // @ts-ignore
  import { v4 as uuidv4 } from "uuid";
  import { DocumentFolder, DocumentType } from "@/store/modules/document.store";

  @Component({
    components: {
      ValidationProvider,
      Spinner: () => import("@/components/general/spinner.vue"),
    },
  })
  export default class FormFile extends Vue {
    @Action("temp-media/upload") uploadTempImage!: TempMediaUpload;
    @Action("temp-media/startUploading") startUploading!: () => void;
    @Action("temp-media/endUploading") endUploading!: () => void;
    @Action("temp-media/reset") resetMedia!: () => void;
    @Action("temp-media/setTotal") setTotal!: (payload: number) => void;
    @Action("temp-media/incrementUploaded") incrementUploaded!: () => void;
    @Action("temp-media/addError") addError!: (payload: any) => void;

    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() rules?: string | object;

    @Prop() label?: string;

    @Prop() labelClass!: string;

    @Prop() documentType!: DocumentType;

    @Prop() documentFolder!: DocumentFolder;

    @Prop({ default: false }) skipMutation!: boolean;

    @Prop({ default: true }) showError?: boolean;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: true }) canUpload!: boolean;

    @Prop({ default: false }) multiple!: boolean;

    @Prop({ default: false }) hideSpinner!: boolean;

    @Ref() input!: HTMLInputElement;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    isFocussed = false;

    localValue = "";

    validAtleastOnce = false;

    loading = false;

    private get formValdiated(): boolean {
      return this.provider.$_veeObserver?.flags?.validated;
    }

    private get validateAs(): string {
      if (this.label) {
        return this.label;
      }

      if (this.name) {
        return this.name;
      }

      return "image field";
    }

    private eagerIfDirty(_a: { flags: any; errors: any }) {
      let errors = _a.errors;
      let flags = _a.flags;
      if (errors.length) {
        return {
          on: ["input", "change"],
        };
      }

      if (flags.valid && !this.validAtleastOnce) {
        this.validAtleastOnce = true;
      }

      if (flags.dirty) {
        return {
          on: ["change", "blur", "input"],
        };
      }

      return {
        on: ["change"],
      };
    }

    @Emit("change")
    async handleFileChange(e: any) {
      this.loading = true;

      let files = e.target.files || e.dataTransfer.files;

      this.provider.validate(e);

      if (!files.length) return null;

      if (!this.skipMutation) {
        this.resetMedia();

        this.startUploading();
      }

      this.setTotal(Array.from(files).length);

      let medias: any = [];
      let promises: any = [];
      Array.from(files).forEach((f: any) => {
        const payload: TempMediaUploadPayload = {
          name: f.name,
          uuid: uuidv4(),
          file: f,
          type: this.documentType,
          folder: this.documentFolder,
          skip_mutation: this.skipMutation,
        };
        promises.push(
          this.uploadTempImage(payload)
            .then((media) => {
              media.type = this.documentType;
              media.folder = this.documentFolder;
              medias.push(media);
            })
            .catch((e) => {
              this.addError(e);
            })
            .finally(() => {
              this.incrementUploaded();
            }),
        );
      });

      await Promise.all(promises);

      this.loading = false;

      if (!this.skipMutation) {
        this.endUploading();
      }

      if (!this.multiple) {
        return medias[0];
      }

      return medias;
    }

    @Emit("blur")
    private handleBlur(e: Event): Event {
      if (this.provider && this.provider.flags.dirty) {
        this.validAtleastOnce = true;
      }
      this.isFocussed = false;
      return e;
    }

    @Emit("focus")
    private handleFocus(e: Event): Event {
      this.isFocussed = true;
      return e;
    }

    @Emit("click")
    private handleClick(e: Event): Event {
      if (e.target) {
        //@ts-ignore
        e.target.value = "";
      }
      return e;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: string) {
      this.localValue = newValue;
    }
  }
