import { ActionTree } from "vuex";

const initialState: MediaState = {
  errors: [],
};

const actions: ActionTree<MediaState, RootState> = {
  url({ commit, rootState }, id: string) {
    return rootState.api
      .get(`media/${id}/url`, { withCredentials: true })
      .then((response: { data: Document[] }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
};
