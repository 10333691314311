import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import modules from "@/store/modules";
import axios from "axios";
import { Office } from "./modules/office.store";

Vue.use(Vuex);

const preferredOfficeId = localStorage.getItem("hubr-preferred-office-id") ? Number(localStorage.getItem("hubr-preferred-office-id")) : false;

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

if (preferredOfficeId) {
  api.defaults.headers["x-office-id"] = preferredOfficeId;
}

const debug = process.env.NODE_ENV !== "production";
const store: StoreOptions<RootState> = {
  state: {
    version: process.env.VUE_APP_VERSION,
    api,
    page_loading: false,
    office_id: preferredOfficeId ? preferredOfficeId : NaN,
  },
  modules,
  strict: debug,
  getters: {
    pageLoading(state: RootState): boolean {
      return state.page_loading;
    },
    currentOffice(state: RootState): number | null {
      return state.office_id;
    },
    entityOfficeIds(state: RootState): number[] {
      if (!state.office_id) {
        return [];
      }

      // TODO: this rule should be more abstract.
      // Right now if the office is either Liedekerke or Denderhoutem, both are allowed as 1 entity
      if ([1, 2, 5].includes(state.office_id)) {
        return [1, 2, 5];
      }

      return [state.office_id];
    },
    currentEntityOffices(state: RootState): Office[] {
      if (!state.office || !state.office.all || state.office.all.length === 0) {
        return [];
      }

      const viewingOffice = state.office.all.find((o: Office) => o.id === state.office_id);

      if (!viewingOffice) {
        return [];
      }

      return state.office.all.filter((o: Office) => o.entity === viewingOffice.entity);
    },
  },
  mutations: {
    PAGE_LOADING(state: RootState) {
      state.page_loading = true;
    },
    PAGE_LOADED(state: RootState) {
      state.page_loading = false;
    },
    SET_OFFICE_ID(state: RootState, payload: number) {
      state.office_id = payload;
    },
    CLEAR_OFFICE_ID(state: RootState) {
      state.office_id = NaN;
    },
  },
  actions: {
    reset({ commit }: any) {
      const promises: any = [];
      Object.keys(modules).forEach((moduleName: string) => {
        commit(`${moduleName}/RESET`);
        promises.push(Promise.resolve());
      });

      return Promise.all(promises).then(() => Promise.resolve());
    },
    startLoadingPage({ commit }: any) {
      commit(`PAGE_LOADING`);
    },
    stopLoadingPage({ commit }: any) {
      commit(`PAGE_LOADED`);
    },
    setOfficeId({ state, commit }: any, payload: number) {
      commit(`SET_OFFICE_ID`, payload);

      state.api.defaults.headers["x-office-id"] = payload;

      localStorage.setItem("hubr-preferred-office-id", `${payload}`);
    },
    clearOfficeId({ state, commit }: any) {
      commit(`CLEAR_OFFICE_ID`);

      localStorage.removeItem("hubr-preferred-office-id");
    },
  },
};

api.interceptors.response.use(
  (res) => res.data,
  (err) => {
    const errorResponse: ErrorResponse = {
      status: err.response.status,
      errors: [],
    };

    if (!Object.prototype.hasOwnProperty.call(err.response.data, "errors")) {
      const error: ServerError = {
        message: "server_error",
      };
      errorResponse.errors.push(error);

      throw errorResponse;
    }

    errorResponse.errors = err.response.data.errors;

    throw errorResponse;
  },
);

const rootStore = new Vuex.Store<RootState>(store);

export default rootStore;
