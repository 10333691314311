import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";

export enum TeamFunctions {
  buyer = "BUYER",
  marketeer = "MARKETER",
  startup = "STARTUP",
  financeExecution = "FINANCE_EXECUTION",
  seller = "SELLER",
  financeControl = "FINANCE_CONTROL",
  backoffice = "BACKOFFICE",
  surveryor = "SURVEYOR",
  legal = "LEGAL",
}

export interface IndexActivityAccountPayload {
  q?: {
    and: {
      activity_id: number | number[];
    };
  };
}

export interface CreateActivityAccountPayload {
  activity_id: number;
  account_id: number;
  type: TeamFunctions;
}

export interface PatchActivityAccountPayload {
  id: number;
  activity_id?: number;
  account_id?: number;
  type?: TeamFunctions;
}

export interface ActivityAccount {
  id: number;
  relation_id: number;
  email: string;
  first_name: string;
  last_name: string;
  media: Media;
  activity_id: number;
  account_id: number;
  type: TeamFunctions;
}

interface ActivityAccountState {
  all: ActivityAccount[];
}

const initialState: ActivityAccountState = {
  all: [],
};

const getters: GetterTree<ActivityAccountState, RootState> = {
  all(state: ActivityAccountState): ActivityAccount[] {
    return state.all;
  },
};

const mutations: MutationTree<ActivityAccountState> = {
  RESET(state: ActivityAccountState) {
    state.all = [];
  },
  ADD_MODEL(state: ActivityAccountState, payload: ActivityAccount) {
    state.all.push(payload);
  },
  SET_MODELS(state: ActivityAccountState, payload: ActivityAccount[]) {
    state.all = payload;
  },
  ADD_MODELS(state: ActivityAccountState, payload: ActivityAccount[]) {
    state.all = [...state.all, ...payload];
  },
  DELETE_MODEL(state: ActivityAccountState, id: number) {
    // @ts-ignore
    const index = state.all.findIndex((model: ActivityAccount) => model.id == id);

    if (index >= 0) {
      state.all.splice(index, 1);
    }
  },
};

const actions: ActionTree<ActivityAccountState, RootState> = {
  index({ commit, rootState }, payload: IndexActivityAccountPayload) {
    return rootState.api
      .get(`activity-accounts?${queryFromObject(payload)}`, { withCredentials: true })
      .then((response: { data: ActivityAccount[] }) => {
        commit("SET_MODELS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  create({ commit, rootState }, payload: CreateActivityAccountPayload) {
    return rootState.api
      .post(`activity-accounts`, payload, { withCredentials: true })
      .then((response: { data: ActivityAccount }) => {
        commit("ADD_MODEL", response.data);

        commit("activity/ADD_ASSIGNED_TO", response.data, { root: true });

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: PatchActivityAccountPayload) {
    return rootState.api
      .patch(`activity-accounts/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: ActivityAccount }) => {
        commit("activity/UPDATE_ASSIGNED_TO", response.data, { root: true });

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ commit, rootState }, id: number) {
    return rootState.api
      .delete(`activity-accounts/${id}`, { withCredentials: true })
      .then((response: {}) => {
        commit("DELETE_MODEL", id);

        commit("activity/DELETE_ASSIGNED_TO", id, { root: true });

        return Promise.resolve(id);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
