import { render, staticRenderFns } from "./phone.vue?vue&type=template&id=0e7312ee&scoped=true"
import script from "./phone.vue?vue&type=script&lang=ts"
export * from "./phone.vue?vue&type=script&lang=ts"
import style0 from "./phone.vue?vue&type=style&index=0&id=0e7312ee&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e7312ee",
  null
  
)

export default component.exports