import guardAuth from "@/router/middleware/auth";
import { loadView } from "@/router/helpers";

const routes = [
  {
    path: "/",
    name: "partner",
    component: loadView("partner/index"),
    children: [
      {
        path: "dashboard",
        name: "partner-dashboard",
        beforeEnter: guardAuth,
        component: loadView("partner/dashboard"),
        meta: {
          title: "Uw Partner dashboard",
          group: ["partner"],
        },
      },
      {
        path: "request/:id",
        name: "partner-request",
        beforeEnter: guardAuth,
        component: loadView("partner/request"),
        meta: {
          title: "Uw Partner dashboard",
          group: ["partner"],
        },
      },
      {
        path: "mijn-profiel",
        name: "partner-account",
        beforeEnter: guardAuth,
        component: loadView("partner/account"),
        meta: {
          title: "Uw Partner account",
          group: ["partner"],
        },
      },
    ],
  },
];

export default routes;
