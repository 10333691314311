import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";

export enum PublicationType {
  website = "WEBSITE",
  immovlan = "IMMOVLAN",
  zimmo = "ZIMMO",
  immoscoop = "IMMOSCOOP",
  immoweb = "IMMOWEB",
  realo = "REALO",
  logicImmo = "LOGIC_IMMO",
}

export interface IndexPublicationPayload {
  q?: {
    and?: {
      activity_id?: number | number[];
    };
  };
  per_page?: number;
}

export interface CreatePublicationPayload {
  type: PublicationType;
  url: string;
  activity_id: number;
}

export interface UpdatePublicationPayload {
  id: number;
  type: PublicationType;
  url: string;
  activity_id: number;
}

export interface PatchPublicationPayload {
  id: number;
  type?: PublicationType;
  url?: string;
  activity_id?: number;
}

export interface Publication {
  id: number;
  type: PublicationType;
  url: string;
  activity_id: number;
  created_at: string;
  updated_at: string;
}

interface PublicationState {
  all: Publication[];
}

const initialState: PublicationState = {
  all: [],
};

const getters: GetterTree<PublicationState, RootState> = {
  all(state: PublicationState): Publication[] {
    return state.all;
  },
};

const mutations: MutationTree<PublicationState> = {
  RESET(state: PublicationState) {
    state.all = [];
  },
  ADD_MODEL(state: PublicationState, payload: Publication) {
    state.all.push(payload);
  },
  SET_MODELS(state: PublicationState, payload: Publication[]) {
    state.all = payload;
  },
  ADD_MODELS(state: PublicationState, payload: Publication[]) {
    state.all = [...state.all, ...payload];
  },
  UPDATE_MODEL(state: PublicationState, payload: Publication) {
    const index = state.all.findIndex((model: Publication) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }
  },
};

const actions: ActionTree<PublicationState, RootState> = {
  index({ commit, rootState }, payload: IndexPublicationPayload) {
    return rootState.api
      .get(`publications?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true })
      .then((response: { data: Publication[] }) => {
        commit("SET_MODELS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: number) {
    return rootState.api
      .get(`publications/${payload}`, { withCredentials: true })
      .then((response: { data: Publication }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  create({ commit, rootState }, payload: CreatePublicationPayload) {
    return rootState.api
      .post(`publications`, payload, { withCredentials: true })
      .then((response: { data: Publication }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  update({ commit, rootState }, payload: UpdatePublicationPayload) {
    return rootState.api
      .put(`publications/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: Publication }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ commit, rootState }, payload: number) {
    return rootState.api
      .delete(`publications/${payload}`, { withCredentials: true })
      .then((response: { data: Publication }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: UpdatePublicationPayload) {
    return rootState.api
      .patch(`publications/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: Publication }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
